import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {combineLatest, Observable, Subject, of} from "rxjs";
import {IDomainCreate, IDomainModel, IDomainsState} from "../models/domain.model";
import {filter, map, mergeMap, take, takeUntil, tap} from "rxjs/operators";
import {GetDomainsSuccess, ChangeAhrefsDate, ChangeIndexDate} from "../store/actions/domains.actions";
import {getDomains} from "../store/reducers/domains.reducer";
import {getCompanies} from "../store/reducers/companies.reducer";
import {IUserModel, IUsersState, IUsersExtraState} from "../models/user.model";
import {ICompanyModel, ICompanyState} from "../models/company.model";
import {GetCompaniesSuccess} from "../store/actions/companies.actions";
import {GetUsersSuccess} from "../store/actions/users.actions";
import {GetUsersExtraSuccess} from "../store/actions/usersextra.actions";
import {GetWhitelistSuccess, RemoveWhitelist, AddWhitelist, EditWhitelist} from "../store/actions/whitelist.actions";
import {GetCFDataSuccess, RemoveCFData, AddCFData, EditCFData} from "../store/actions/cfdata.actions";
import {GetCFActionsDomainsSuccess, CFActionSuccess} from "../store/actions/cfactions.actions";
import {GetCFMailsSuccess, CFMailSuccess} from "../store/actions/cfmails.actions";
import {getUsers} from "../store/reducers/users.reducer";
import {ICfTrafficModel} from "../models/cfTraffic.model";
import {GetCfTrafficSuccess} from "../store/actions/cfTraffic.actions";
import {getTraffics} from "../store/reducers/cfTraffic.reducer";
import {IServerModel, IServerState} from "../models/server";
import {GetServersSuccess} from "../store/actions/server.action";
import {getServers} from "../store/reducers/servers.reducer";

@Injectable()

export class ApiService {

    constructor(
        private HTTP: HttpClient,
        private STORE: Store<any>
    ) {
    }

    getDomains(tab: string = 'all'): Observable<IDomainsState> {
        return combineLatest([
            this.HTTP.get<IDomainsState>('/domains/get/' + tab),
            this.STORE.select(getUsers),
            this.STORE.select(getTraffics),
            this.STORE.select(getServers),
        ])
            .pipe(
                map(([res, users, traffics, servers]) => {
                    const regex = /https/;
                    let domainsArray;
                    if (users !== null) {
                        domainsArray = res.domains.map((item) => {
                            const obj = {...item};
                            const user = users.find(u => obj.userId === u._id);
                            if (traffics) {
                                for (const trafficKey in traffics) {
                                    if (obj._id.toString() === trafficKey.toString()) {
                                        obj.cf_traffic_value = traffics[obj._id];
                                    }
                                    if (obj.cf_traffic_value === undefined) {
                                        let isCF = false;
                                        if (obj.whois?.dns) {
                                            for (const this_dns of obj.whois.dns) {
                                                if (this_dns.toLowerCase().includes('cloudflare')) {
                                                    isCF = true;
                                                    break;
                                                }
                                            }
                                        }
                                        if (isCF) {
                                            obj.cf_traffic_value = 'no creds CF';
                                        } else {
                                            obj.cf_traffic_value = 'NOT CF';
                                        }
                                    }
                                }
                            }
                            if (user) {
                                obj.authorName = user.name;
                                if (obj.date.created === undefined) {
                                    obj.date.created = '0000:0000:0000';
                                }
                            }
                            /*if (servers) {
                                for (let i = 0; i < servers.length; i++) {
                                    if (obj.server_id === servers[i]._id) {
                                        obj.server_ip = servers[i].server_ip;
                                    }
                                }
                            }*/
                            return obj;
                        });
                        return domainsArray;
                    }
                }),
                tap((res) => {
                    this.STORE.dispatch(new GetDomainsSuccess(res));
                })
            );
    }

    getByDomains(body: any): Observable<any> {
        return this.HTTP.post('/domains/get_by_domains', body);
    }

    setDNSIP(body: any): Observable<any> {
        return this.HTTP.post('/domains/set_dns_ip', body);
    }

    getWPInfo(body: any): Observable<any> {
        return this.HTTP.post('/domains/get_wp_info', body);
    }

    updateWP(body: any): Observable<any> {
        return this.HTTP.post('/domains/update_wp', body);
    }

    checkDomains(tab: string = 'all'): void {
        this.STORE.select(getDomains)
            .pipe(
                take(1),
                tap(res => {
                    if (!res) {
                        this.getDomains(tab).subscribe();
                    }
                })
            )
            .subscribe();
    }

    getUsers(): Observable<IUsersState> {
        return this.HTTP.get<IUsersState>('/profile/users')
            .pipe(tap(res => {
                this.STORE.dispatch(new GetUsersSuccess(res));
            }));
    }

    checkUsers(): void {
        this.STORE.select(getUsers)
            .pipe(
                take(1),
                tap(res => {
                    if (!res) {
                        this.getUsers().subscribe();
                    }
                })
            )
            .subscribe();
    }

    getServers(): Observable<IServerState> {
        return this.HTTP.get<any>('/servers/').pipe(tap(res => {
            if (res.success) {
                this.STORE.dispatch(new GetServersSuccess(res.success));
            }
        }));
    }

    checkServers(): void {
        this.STORE.select(getServers)
            .pipe(
                take(1),
                tap(res => {
                    if (!res) {
                        this.getServers().subscribe();
                    }
                })
            )
            .subscribe();
    }

    addServer(body: IServerModel): Observable<any> {
        return this.HTTP.post('/servers', body)
            .pipe(
                tap(res => {
                    this.getServers().subscribe();
                })
            );
    }

    editServer(body: any): Observable<any> {
        return this.HTTP.patch('/servers/update', body)
            .pipe(
                tap(
                    res => {
                        this.getServers().subscribe();
                    }
                )
            );
    }

    deleteServer(id: string): Observable<any> {
        return this.HTTP.post('/servers/remove', {id});
    }

    getCfTraffic(): Observable<ICfTrafficModel> {
        return this.HTTP.get<ICfTrafficModel>('/domains/traffic')
            .pipe(tap(res => {
                this.STORE.dispatch(new GetCfTrafficSuccess(res));
            }));
    }

    addDomain(body: IDomainCreate): Observable<any> {
        return this.HTTP.post('/domains', body)
            .pipe(
                tap(res => {
                    this.getDomains().subscribe();
                })
            )
    }

    createDomain(body: IDomainCreate): Observable<any> {
        return this.HTTP.post('/domains/add', body)
            .pipe(
                tap(res => {
                    this.getDomains().subscribe();
                })
            )
    }


    addCfKey(body: any): Observable<any> {
        return this.HTTP.post('/domains/cf_add', body)
            .pipe(
                tap(res => {
                    this.getCfTraffic().subscribe();
                })
            );
    }

    //
    // editDomain(  body: any): Observable<any> {
    //     return this.HTTP.patch('/domains/multi', body)
    //         .pipe(
    //             tap(
    //             res => {
    //                     this.getDomains().subscribe();
    //             }
    //         )
    //     );
    // }

    editDomain(body: any): Observable<any> {
        return this.HTTP.patch('/domains/edit', body)
            .pipe(
                tap(
                    res => {
                        this.getDomains().subscribe();
                    }
                )
            );
    }

    deleteDomain(id: string): Observable<any> {
        return this.HTTP.delete('/domains/' + id)
            .pipe(
                tap(res => {
                    this.getDomains().subscribe();
                })
            )
    }

    archiveDomain(body: any): Observable<any> {
        return this.HTTP.post('/domains/archive', body);
    }

    checkWhois(ids: any): Observable<any> {
        return this.HTTP.post('/domains/check_whois', ids);
    }

    checkRobots(ids: any): Observable<any> {
        return this.HTTP.post('/domains/check_robots', ids);
    }

    checkAhrefs(ids: any): Observable<any> {
        return this.HTTP.post('/domains/check_ahrefs', ids);
    }

    checkSsl(ids: any): Observable<any> {
        return this.HTTP.post('/domains/check_ssl', ids);
    }

    checkStatus(ids: any): Observable<any> {
        return this.HTTP.post('/domains/check_status', ids);
    }

    checkIndex(ids: any): Observable<any> {
        return this.HTTP.post('/domains/check_dfs_index', ids);
    }

    checkWpSecurity(ids: any): Observable<any> {
        return this.HTTP.post('/domains/check_wp_security', ids);
    }

    checkCFTraffic(identificator: string): Observable<any> {
        return this.HTTP.post('/domains/check_traffic', {id: identificator});
    }

    getCompanies(): Observable<any> {
        return this.HTTP.get<ICompanyState>('/company/get')
            .pipe(tap(res => {
                this.STORE.dispatch(new GetCompaniesSuccess(res.success));
            }));
    }

    checkCompanies(): void {
        this.STORE.select(getCompanies)
            .pipe(
                tap(res => {
                    if (!res) {
                        this.getCompanies().subscribe();
                    }
                })
            )
            .subscribe();
    }

    getDomainByID(id: string): Observable<any> {
        return this.HTTP.get('/domains/' + id);
    }

    domainsList(): Observable<any> {
        return this.HTTP.get('/domains/domains_list/');
    }

    getUserByID(id: string): Observable<any> {
        return this.HTTP.post('/profile/user_by_id/', {id});
    }

    getUsersByID(ids: string[]): Observable<any> {
        return this.HTTP.post('/profile/users_by_id/', {ids});
    }

    getCurrentUser(): Observable<any> {
        return this.HTTP.get('/profile/current_user/');
    }

    getParsedByID(id: string): Observable<any> {
        return this.HTTP.get('/parsed/' + id);
    }

    getExternalHistoryByID(id: string): Observable<any> {
        return this.HTTP.get('/parsed/external_history/' + id);
    }

    setWPCreds(body: any): Observable<any> {
        return this.HTTP.post('/domains/wp_credentials_add/', body);
    }

    getWhitelist(type = 'domain'): Observable<any> {
        if(type != 'domain'){
            return this.HTTP.post('/whitelist/get', {type: type}).pipe(tap(res => {
                if (res.success) {
                    this.STORE.dispatch(new GetWhitelistSuccess(res.success));
                }
            }));
        }else{
            return this.HTTP.get('/whitelist/get').pipe(tap(res => {
                if (res.success) {
                    this.STORE.dispatch(new GetWhitelistSuccess(res.success));
                }
            }));
        }
    }

    addToWhitelist(body: any, no_store = false): Observable<any> {
        return this.HTTP.post('/whitelist/add', body).pipe(tap(res => {
            if (res.success && !no_store) {
                this.STORE.dispatch(new AddWhitelist(res.success));
            }
        }));
    }

    removeFromWhitelist(body: any, no_store = false): Observable<any> {
        return this.HTTP.post('/whitelist/delete', body).pipe(tap(res => {
            if (res.success && res.success.length > 0  && !no_store) {
                this.STORE.dispatch(new RemoveWhitelist(res.success[0]));
            }
        }));
    }

    editWhitelist(body: any, no_store = false): Observable<any> {
        return this.HTTP.patch('/whitelist/edit', body).pipe(tap(res => {
            if (res.success && !no_store) {
                this.STORE.dispatch(new EditWhitelist(res.success));
            }
        }));
    }

    getCFData(): Observable<any> {
        return this.HTTP.get('/cfdata/get').pipe(tap(res => {
            if (res.success) {
                this.STORE.dispatch(new GetCFDataSuccess(res.success));
            }
        }));

    }

    addToCFData(body: any): Observable<any> {
        return this.HTTP.post('/cfdata/add', body).pipe(tap(res => {
            if (res.success) {
                this.STORE.dispatch(new AddCFData(res.success));
            }
        }));
    }

    removeFromCFData(body: any): Observable<any> {
        return this.HTTP.post('/cfdata/delete', body).pipe(tap(res => {
            if (res.success && res.success.length > 0) {
                this.STORE.dispatch(new RemoveCFData(res.success[0]));
            }
        }));
    }

    editCFData(body: any): Observable<any> {
        return this.HTTP.patch('/cfdata/edit', body).pipe(tap(res => {
            if (res.success) {
                this.STORE.dispatch(new EditCFData(res.success));
            }
        }));
    }

    getCFActionsDomains(): Observable<any> {
        return this.HTTP.get('/cfactions/domains').pipe(tap(res => {
            if (res.success) {
                this.STORE.dispatch(new GetCFActionsDomainsSuccess(res.success));
            }
        }));
    }

    changeUseHttpsDomains(body: any): Observable<any> {
        return this.HTTP.post('/cfactions/https-change', body).pipe(tap(res => {
            if (res.success?.success) {
                this.STORE.dispatch(new CFActionSuccess(res.success.success));
            }
        }));
    }

    getUseHttpsDomains(body: any): Observable<any> {
        return this.HTTP.post('/cfactions/https-get', body).pipe(tap(res => {
            if (res.success?.success) {
                this.STORE.dispatch(new CFActionSuccess(res.success.success));
            }
        }));
    }

    changeDevModeDomains(body: any): Observable<any> {
        return this.HTTP.post('/cfactions/dev-mode-change', body).pipe(tap(res => {
            if (res.success?.success) {
                this.STORE.dispatch(new CFActionSuccess(res.success.success));
            }
        }));
    }

    getDevModeDomains(body: any): Observable<any> {
        return this.HTTP.post('/cfactions/dev-mode-get', body).pipe(tap(res => {
            if (res.success?.success) {
                this.STORE.dispatch(new CFActionSuccess(res.success.success));
            }
        }));
    }

    getSSLModeDomains(body: any): Observable<any> {
        return this.HTTP.post('/cfactions/ssl-mode-get', body).pipe(tap(res => {
            if (res.success?.success) {
                this.STORE.dispatch(new CFActionSuccess(res.success.success));
            }
        }));
    }

    changeSSLModeDomains(body: any): Observable<any> {
        return this.HTTP.post('/cfactions/ssl-mode-change', body).pipe(tap(res => {
            if (res.success?.success) {
                this.STORE.dispatch(new CFActionSuccess(res.success.success));
            }
        }));
    }

    WP_CLI(body: any): Observable<any> {
        return this.HTTP.post('/domains/wp_cli/', body);
    }

    getUpdated(): Observable<any> {
        return this.HTTP.get('/updated/');
    }

    getPagesWithLink(body: any): Observable<any> {
        return this.HTTP.post('/parsed/pages_with_link/', body);
    }

    getIndexed(body: any): Observable<any> {
        return this.HTTP.post('/indexed/get_indexed/', body);
    }

    getAllIndexed(): Observable<any> {
        return this.HTTP.get('/indexed/get_all_indexed/');
    }

    linkIndex(body: any): Observable<any> {
        return this.HTTP.post('/indexed/link_index/', body);
    }

    linksIndex(body: any): Observable<any> {
        return this.HTTP.post('/indexed/links_index/', body);
    }

    getLinksByDomainHistory(): Observable<any> {
        return this.HTTP.get('/parsed/history/');
    }

    getDomainsCount(): Observable<any> {
        return this.HTTP.get('/domains/get_count');
    }

    getPendingTasks(): Observable<any> {
        return this.HTTP.get('/pending_tasks/get_tasks/');
    }

    setPendingTasks(body: any): Observable<any> {
        return this.HTTP.post('/pending_tasks/set_tasks/', body);
    }

    deletePendingTask(id: string): Observable<any> {
        return this.HTTP.post('/pending_tasks/delete_tasks/', {id});
    }

    statusHistory(id: string): Observable<any> {
        return this.HTTP.post('/domains/status_history/', {id});
    }

    getDataByDomains(body: any): Observable<any> {
        return this.HTTP.post('/cfdata/data-by-domains', body);
    }

    syncByDomain(domain: string): Observable<any> {
        return this.HTTP.post('/cfdata/sync-by-domain', {domain});
    }

    updateWhitelistData(): Observable<any> {
        return this.HTTP.get('/whitelist/update-data/');
    }

    clearDomainsCache(body: any): Observable<any> {
        return this.HTTP.post('/cfactions/purge-cache', body);
    }

    getCFNoCredsDomains(): Observable<any> {
        return this.HTTP.get('/cfdata/no-creds/');
    }

    getExcelData(): Observable<any> {
        return this.HTTP.get('/domains/excel');
    }

    getMostLinkedDomains(): Observable<any> {
        return this.HTTP.get('/parsed/most_linked_domains');
    }

    getBotsSecurity(id: string): Observable<any> {
        return this.HTTP.post('/domains/bots_security', {id});
    }

    getServerInfo(domain: string): Observable<any> {
        return this.HTTP.post('/servers/info', {domain});
    }

    getDomainHealth(id: string): Observable<any> {
        return this.HTTP.post('/domains/domain_health', {id});
    }

    changeAhrefsDate(start: number, end: number): void {
        this.STORE.dispatch(new ChangeAhrefsDate({start, end}));
    }

    changeIndexDate(start: number, end: number): void {
        this.STORE.dispatch(new ChangeIndexDate({start, end}));
    }

    preCheck(domain: string): Observable<any> {
        return this.HTTP.post('/domains/pre_check', {domain});
    }

    setCollaborators(body: any): Observable<any> {
        return this.HTTP.post('/domains/set_collaborators', body);
    }

    getTransferDomainsByUserId(id: string): Observable<any> {
        return this.HTTP.post('/domains/td_by_user_id', {id});
    }

    transferDomains(body: any): Observable<any> {
        return this.HTTP.post('/domains/transfer', body);
    }

    getTransferHistory(): Observable<any> {
        return this.HTTP.get('/domains/get_transfer_history');
    }

    transferBackup(id: string): Observable<any> {
        return this.HTTP.post('/domains/transfer_backup', {id});
    }

    getServersByUser(): Observable<any> {
        return this.HTTP.get('/servers/get_by_user');
    }

    editExplanation(body: any): Observable<any> {
        return this.HTTP.patch('/servers/edit_explanation', body);
    }

    getCollaboratorsDomainsByUserId(): Observable<any> {
        return this.HTTP.get('/domains/cd_by_user_id');
    }

    getExtraDomainsByUserId(id: string): Observable<any> {
        return this.HTTP.post('/domains/extra_by_user_id', {id});
    }

    getExtraCollDomainsByUserId(id: string): Observable<any> {
        return this.HTTP.post('/domains/extra_coll_by_user_id', {id});
    }

    changeWPAdminPass(body: any): Observable<any> {
        return this.HTTP.post('/domains/change_wp_pass', body);
    }

    getUsersExtra(): Observable<any> {
        return this.HTTP.get<IUsersExtraState>('/profile/all_extra').pipe(tap(res => {
            if(res.success) this.STORE.dispatch(new GetUsersExtraSuccess(res.success));
        }));
    }

    getIdeas(mark: string): Observable<any> {
        return this.HTTP.post('/ideas/get', {mark});
    }

    getCFDataDomains(): Observable<any> {
        return this.HTTP.get('/cfdata/domains');
    }

    getIdeasDomains(id: number, mark: string): Observable<any> {
        return id != -1 ? this.HTTP.post('/ideas/domains', {id, mark}) : of({empty: 'no params'});
    }

    addCFDataEmail(id: string, email: string): Observable<any> {
        return this.HTTP.post('/cfdata/add_email', {id, email});
    }

    deleteCFDataEmail(id: string, cf_id: string): Observable<any> {
        return this.HTTP.post('/cfdata/delete_email', {id, cf_id});
    }

    updateCFDataEmails(id: string): Observable<any> {
        return this.HTTP.post('/cfdata/update_emails', {id});
    }

    getCFMailsDomains(): Observable<any> {
        return this.HTTP.get('/cfmail/all').pipe(tap(res => {
            if(res.success) {
                this.STORE.dispatch(new GetCFMailsSuccess(res.success));
            }
        }));
    }

    getCFMailsDomain(id: string): Observable<any> {
        return this.HTTP.post('/cfmail/get', {id});
    }

    addCFMailsDomain(domain: string): Observable<any> {
        return this.HTTP.post('/cfmail/add', {domain});
    }

    deleteCFMailsDomain(id: string[]): Observable<any> {
        return this.HTTP.post('/cfmail/remove', {id});
    }

    addCFMailsRule(id: string, from: string, to: string): Observable<any> {
        return this.HTTP.post('/cfmail/add_rule', {id, from, to});
    }

    deleteCFMailsRule(id: string, rule_id: string, email: string): Observable<any> {
        return this.HTTP.post('/cfmail/remove_rule', {id, rule_id, email});
    }

    updateCFMailRules(id: string): Observable<any> {
        return this.HTTP.post('/cfmail/update_rules', {id});
    }

    getVerifiedEmails(id: any[]): Observable<any> {
        return this.HTTP.post('/cfdata/get_verified_mails', {id});
    }

    updateEmailRouting(id: string): Observable<any> {
        return this.HTTP.post('/cfmail/update_email_routing', {id});
    }

    setEmailRouting(id: string, enable: boolean): Observable<any> {
        return this.HTTP.post('/cfmail/set_email_routing', {id, enable});
    }

    getWPSSO(domain: string): Observable<any> {
        return this.HTTP.post('/domains/get_wp_sso', {domain});
    }

    getTransferHystoryDomains(domains: string[]): Observable<any> {
        return this.HTTP.post('/domains/transfer_history_domains', {domains});
    }

    duplicateDomain(domain: string): Observable<any> {
        return this.HTTP.post('/domains/duplicate_domain', {domain});
    }

    setRedirect(domains: string[], redirect_url: string, hostname_redirect: boolean): Observable<any> {
        return this.HTTP.post('/cfactions/set-redirect', {domains, redirect_url, hostname_redirect});
    }

    editRedirect(domains: string[], redirect_url: string, hostname_redirect: boolean): Observable<any> {
        return this.HTTP.post('/cfactions/edit-redirect', {domains, redirect_url, hostname_redirect});
    }

    setRedirectActivity(domains: string[], enabled: boolean): Observable<any> {
        return this.HTTP.post('/cfactions/set-redirect-activity', {domains, enabled});
    }

    deleteRedirect(domains: string[]): Observable<any> {
        return this.HTTP.post('/cfactions/delete-redirect', {domains});
    }

    updateRedirect(domains: string[]): Observable<any> {
        return this.HTTP.post('/cfactions/update-redirect', {domains});
    }

    updateSecurityLevel(domains: string[]): Observable<any> {
        return this.HTTP.post('/cfactions/update-security-level', {domains});
    }

    setSecurityLevel(domains: string[], level: string): Observable<any> {
        return this.HTTP.post('/cfactions/set-security-level', {domains, level});
    }

    getPostHTML(id: string, post_url: string): Observable<any> {
        let main = false;
        if(post_url == '' || post_url == '/') {
            main = true;
            post_url = '/';
        };
        return main ? this.HTTP.post('/domains/wp_cli_get_post_html', {id, post_url, main: true}) : this.HTTP.post('/domains/wp_cli_get_post_html', {id, post_url});
    }

    updatePostHTML(id: string, post_id: string, post_content: string): Observable<any> {
        return this.HTTP.post('/domains/wp_cli_update_post_html', {id, post_id, post_content});
    }

    getPanelPlugins(): Observable<any> {
        return this.HTTP.get('/domains/panel_plugins');
    }

    setCountryBlock(domains: string[], countries: string[]): Observable<any> {
        return this.HTTP.post('/cfactions/set-country-block', {domains, countries});
    }

    editCountryBlock(domains: string[], countries: string[]): Observable<any> {
        return this.HTTP.post('/cfactions/edit-country-block', {domains, countries});
    }

    setCountryBlockActivity(domains: string[], enabled: boolean): Observable<any> {
        return this.HTTP.post('/cfactions/set-country-block-activity', {domains, enabled});
    }

    deleteCountryBlock(domains: string[]): Observable<any> {
        return this.HTTP.post('/cfactions/delete-country-block', {domains});
    }

    updateCountryBlock(domains: string[]): Observable<any> {
        return this.HTTP.post('/cfactions/update-country-block', {domains});
    }

    getArchivedDomains(): Observable<any> {
        return this.HTTP.get('/domains/archived-domains');
    }

    getUserStats(): Observable<{success: {[key: string] : number[]}}> {
        return this.HTTP.get<{success: {[key: string] : number[]}}>('/domains/get-user-stats');
    }

    pluginDomains(plugin: string, update: boolean): Observable<any> {
        return this.HTTP.post('/domains/plugin-domains', {plugin, update});
    }
}
